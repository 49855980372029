<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col>
        <h1 class="mb-0">Sales Invoice Manage</h1>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <validation-observer ref="salesInvoiceFormRef">
          <b-card>
            <b-tabs v-model="activeTab" fill>
              <b-tab title="Information" active>
                <b-form>
                  <b-row>
                    <b-col>
                      <h2>Customer Information</h2>
                    </b-col>

                    <b-col class="text-right">
                      <b-button
                        @click="goBack()"
                        type="button"
                        class="mr-1"
                        variant="outline-danger"
                      >
                        Back
                      </b-button>
                      <b-button
                        type="button"
                        variant="success"
                        @click="onEdit()"
                      >
                        Edit
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12" md="3">
                      <b-form-group label="Ticket No." label-for="v-ticket_no">
                        <b-form-input
                          disabled
                          v-model="formData.ticket_no"
                          id="v-ticket_no"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="9">
                      <b-form-group
                        label="Company Name"
                        label-for="v-company_name"
                      >
                        <b-form-input
                          v-model="formData.company_name"
                          id="v-company_name"
                          disabled
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group label="Email" label-for="v-email">
                        <b-form-input
                          disabled
                          v-model="formData.email"
                          id="v-email"
                        />
                      </b-form-group>
                    </b-col>
                    <!-- <b-col>
                      <b-form-group
                        label="Sales Person"
                        label-for="v-sales-person"
                      >
                        <v-select
                          disabled
                          v-model="formData.employee_id"
                          :options="users"
                          :reduce="(users) => users.value"
                        />
                      </b-form-group>
                    </b-col> -->
                    <b-col>
                      <b-form-group
                        label="Request Date"
                        label-for="v-transaction_date"
                      >
                        <b-form-input
                          disabled
                          id="v-transaction_date"
                          v-model="formData.preview_transaction_date"
                          type="datetime-local"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group label="Status" label-for="v-status">
                        <b-form-select
                          disabled
                          id="v-status"
                          v-model="formData.status"
                          :options="status"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <h2>Billing Address</h2>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group label="Attn" label-for="v-attn">
                        <b-form-input
                          disabled
                          v-model="formData.billing_name"
                          id="v-attn"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        label="Company"
                        label-for="v-billing_company"
                      >
                        <b-form-input
                          disabled
                          v-model="formData.billing_company"
                          id="v-billing_company"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group label="Email" label-for="v-billing_email">
                        <b-form-input
                          disabled
                          v-model="formData.billing_email"
                          id="v-billing_email"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group
                        label="Building/Block/House No and Street Name"
                        label-for="v-billing_addr1"
                      >
                        <b-form-input
                          disabled
                          v-model="formData.billing_addr1"
                          id="v-billing_addr1"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        label="Unit # and Building Name"
                        label-for="v-billing_addr2"
                      >
                        <b-form-input
                          disabled
                          v-model="formData.billing_addr2"
                          id="v-billing_addr2"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group
                        label="Country"
                        label-for="v-billing_country"
                      >
                        <v-select
                          disabled
                          v-model="formData.billing_country"
                          :options="
                            $store.getters['hbaseGeneral/getAllCountries']
                          "
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group label="Postal" label-for="v-billing_postal">
                        <b-form-input
                          disabled
                          v-model="formData.billing_postal"
                          id="v-billing_postal"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group
                        label="Telephone No."
                        label-for="v-billing_tel"
                      >
                        <b-form-input
                          disabled
                          v-model="formData.billing_tel"
                          id="v-billing_tel"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group label="Fax" label-for="v-billing_fax">
                        <b-form-input
                          disabled
                          v-model="formData.billing_fax"
                          id="v-billing_fax"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <h2>Delivery Address</h2>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group label="Attn" label-for="v-delivery_name">
                        <b-form-input
                          disabled
                          v-model="formData.delivery_name"
                          id="v-delivery_name"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        label="Company"
                        label-for="v-delivery_company"
                      >
                        <b-form-input
                          disabled
                          v-model="formData.delivery_company"
                          id="v-delivery_company"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group label="Email" label-for="v-delivery_email">
                        <b-form-input
                          disabled
                          v-model="formData.delivery_email"
                          id="v-delivery_email"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group
                        label="Building/Block/House No and Street Name"
                        label-for="v-delivery_addr1"
                      >
                        <b-form-input
                          disabled
                          v-model="formData.delivery_addr1"
                          id="v-delivery_addr1"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        label="Unit # and Building Name"
                        label-for="v-delivery_addr2"
                      >
                        <b-form-input
                          disabled
                          v-model="formData.delivery_addr2"
                          id="v-delivery_addr2"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group
                        label="Country"
                        label-for="v-delivery_country"
                      >
                        <v-select
                          disabled
                          v-model="formData.delivery_country"
                          :options="
                            $store.getters['hbaseGeneral/getAllCountries']
                          "
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        label="Postal"
                        label-for="v-delivery_postal"
                      >
                        <b-form-input
                          disabled
                          v-model="formData.delivery_postal"
                          id="v-delivery_postal"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group
                        label="Telephone No."
                        label-for="v-delivery_tel"
                      >
                        <b-form-input
                          disabled
                          v-model="formData.delivery_tel"
                          id="v-delivery_tel"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group label="Fax" label-for="v-delivery_fax">
                        <b-form-input
                          disabled
                          v-model="formData.delivery_fax"
                          id="v-delivery_fax"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <h2>GST and Currency</h2>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="12" md="4">
                      <b-form-group label="GST" label-for="v-gst">
                        <b-form-select
                          disabled
                          @change="onSelectGST($event)"
                          v-model="formData.gst"
                          :options="gsts"
                          id="v-gst"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="2">
                      <b-form-group
                        label="GST Percent"
                        label-for="v-gst_percent"
                      >
                        <b-form-input
                          disabled
                          type="number"
                          v-model="formData.gst_percent"
                          id="v-gst_percent"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                      <b-form-group label="Currency" label-for="v-currency_id">
                        <b-form-select
                          disabled
                          v-model.number="formData.currency_id"
                          :options="currencies"
                          :reduce="(currencies) => +currences.value"
                          id="v-currency_id"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <b-form-group>
                        <SalesInvoiceDetails
                          :is-disabled="true"
                          :data-source="dataSourceSalesInvoiceDetails"
                          :form-data="formData"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="6">
                      <h2>Terms &amp; Conditions</h2>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group>
                        <quill-editor
                          disabled
                          ref="quillEditorRef"
                          v-model="formData.tnc"
                          id="editor"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-form>
              </b-tab>

              <span v-if="$route.query.id">
                <b-tab title="Notes">
                  <Notes
                    ref="notesRef"
                    module="salesorder"
                    :header-id="parseInt(formData.id)"
                  />
                </b-tab>
                <b-tab title="Documents">
                  <Documents
                    ref="documentsRef"
                    module="salesorder"
                    :header-id="parseInt(formData.id)"
                  />
                </b-tab>
                <b-tab title="Logs">
                  <Logs module="salesorder" />
                </b-tab>
                <b-tab title="Comments">
                  <Comments module="salesorder" />
                </b-tab>
              </span>
            </b-tabs>
          </b-card>
        </validation-observer>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import salesInvoiceApi from '@api/sales_invoice_headers';
import gstsApi from '@api/gsts';
import currenciesApi from '@api/currencies';
import usersApi from '@api/users';
import { queryParameters } from '@/schema';

import SalesInvoiceDetails from './components/SalesInvoiceDetails';

export default {
  name: 'SalesInvoiceView',
  components: {
    SalesInvoiceDetails,
  },
  data: () => ({
    activeTab: 0,
    dataSourceSalesInvoiceDetails: [],
    formData: {},
    gsts: [],
    currencies: [],
    users: [],
    status: [
      { text: 'Open', value: 'O' },
      { text: 'Confirmed', value: 'C' },
      { text: 'Void', value: 'V' },
      { text: 'Expired', value: 'E' },
    ],
    isSameAddress: false,
  }),
  mounted() {
    this.loadAvailableTechnicians();
    this.loadGSTs();
    this.loadCurrencies();

    this.formData.preview_transaction_date = this.defaultDateTime();

    if (+this.$route.query.id) {
      this.loadSalesOrder();
    }
  },
  watch: {
    activeTab(v) {
      if (v === 1) {
        this.$refs.notesRef.loadNotes();
      } else if (v === 2) {
        this.$refs.documentsRef.loadDocuments();
      }
    },
  },
  methods: {
    loadAvailableTechnicians() {
      const params = {
        sort: queryParameters.sort,
        page: { size: 1000 },
        status: queryParameters.status,
        filterExt: `roles=user&coyuser&custuser&tech&qcsemployee`,
      };

      usersApi
        .list(params)
        .then(({ data }) => {
          const items = [];

          for (let i = 0; i < data.length; i++) {
            const item = data[i];

            let fullName = item.first_name;
            if (item.last_name) {
              fullName += ` ${item.last_name}`;
            }

            items.push({ label: fullName, value: +item.id, item });
          }

          this.users = items;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadGSTs() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      gstsApi
        .list(params)
        .then(({ data }) => {
          const items = [];
          for (const item of data) {
            const { description, percent } = item;
            const descriptionWithPercent = `${description} - ${percent}%`;
            items.push({
              value: item.name,
              text: descriptionWithPercent,
              percent,
              item,
            });
          }

          if (!this.formData.gst) {
            this.formData.gst = items[0].value;
            this.formData.gst_percent = items[0].percent;
          }

          this.gsts = items;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadCurrencies() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      currenciesApi
        .list(params)
        .then(({ data }) => {
          const items = [];
          for (const item of data) {
            items.push({ value: +item.id, text: item.description, item });
          }

          this.currencies = items;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadSalesOrder() {
      salesInvoiceApi
        .get(this.$route.query.id)
        .then(({ data }) => {
          if (data != null) {
            const { details } = data;
            this.dataSourceSalesInvoiceDetails = details.data ?? [];
            data.preview_transaction_date = this.inputDateTimeFromUnix(
              data.transaction_date
            );

            this.formData = data;
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    goBack() {
      this.$router.push('/pages/sales-tools/sales-invoice');
    },
    onEdit() {
      const url = `/pages/sales-tools/sales-invoice/manage?id=${this.$route.query.id}`;
      this.$router.push(url);
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
